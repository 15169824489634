import React from 'react';

import './upload-form.styles.scss';

import Uploader from '../../components/uploader/uploader.component';

class UploadForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			client_name: '',
			client_postcode: '',
			account_number: '',
            firm_reference: '',
			file_name: '',
			file_content: '',
			token: props.token,
			hasUploaded: false,
			uploadSuccess: false,
		};

        this.resetForm = this.resetForm.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleUpload = this.handleUpload.bind(this);
    	this.handleSubmit = this.handleSubmit.bind(this);
	}

    // Resets form state to how it is when it first renders the component
    resetForm() {
        this.setState({
            client_name: '',
			client_postcode: '',
			account_number: '',
            firm_reference: '',
			file_name: '',
			file_content: '',
			hasUploaded: false,
			uploadSuccess: false
        });
    }

	handleUpload(name, body) {
		this.setState({
			file_name: name,
			file_content: body
		});
	}

	handleChange(event) {
		this.setState({[event.target.id]: event.target.value});
	}

	handleSubmit(event) {
		// Lets make sure a file has been uploaded first
		if(this.state.file_content === '')
		{
			alert('You need to upload a file before submitting the form');
			event.preventDefault();
			return false;
		}

        //eslint-disable-next-line
		let apiCall = fetch('https://03i4h6n1ne.execute-api.eu-west-2.amazonaws.com', {
			method: 'POST',
			headers: new Headers({
				'Authorization': 'Bearer ' + this.state.token,
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}),
			body: JSON.stringify({
				client_name: this.state.client_name,
				client_postcode: this.state.client_postcode,
				account_number: this.state.account_number,
                firm_reference: this.state.firm_reference,
				file_name: this.state.file_name,
				file_content: this.state.file_content
			})
		})
		.then((response) => {
			if (response.status >= 400 && response.status < 600) {
				throw new Error("error");
			}
			return response.json();
		})
		.then((json) => {
			this.setState({
				hasUploaded: true,
				uploadSuccess: true
			});
		})
		.catch((error) => {
			this.setState({
				hasUploaded: true
			});
		});
		event.preventDefault();
	}

	render()
	{
		if (this.state.hasUploaded === false)
		{
			return (
				<form id="upload-form" onSubmit={this.handleSubmit}>
					<Uploader handleUpload={this.handleUpload}/>
					<h2 className='mt-4'>File Name Information:</h2>
		            <div className="row">
		                <div className="col">
		                    <label htmlFor="client_name">Client Surname:</label>
		                    <input id="client_name" required name="client_name" type="text" placeholder="Enter your client's surname here" className="form-control" value={this.state.client_name} onChange={this.handleChange} />
		                </div>
		            </div>
		            <div className="row">
		                <div className="col">
		                    <label htmlFor="client_postcode">Client Postcode:</label>
		                    <input id="client_postcode" required name="client_postcode" type="text" placeholder="Enter your client's postcode here" className="form-control" value={this.state.client_postcode} onChange={this.handleChange} />
		                </div>
		            </div>
		            <div className="row">
		                <div className="col">
		                    <label htmlFor="account_number">Account Number:</label>
		                    <input id="account_number" required name="account_number" type="text" placeholder="Enter your account number here" className="form-control" value={this.state.account_number} onChange={this.handleChange} />
		                </div>
		            </div>
                    <div className="row">
		                <div className="col">
		                    <label htmlFor="firm-ref">Firm Reference:</label>
		                    <input id="firm_reference" required name="firm_reference" type="text" placeholder="Enter your firm reference here" className="form-control" value={this.state.firm_reference} onChange={this.handleChange} />
		                </div>
		            </div>
		            <div className="row justify-content-center">
		                <div className="col-4">
		                    <button type="submit">Submit</button>
		                </div>
		            </div>
		        </form>
			)
		}
		else
		{
			if (this.state.uploadSuccess === false)
			{
				return (
					<div className='api-message'>
						<h1>FAILED</h1>
						<p>Something went wrong with your upload, please try again... <br />If this error persists please let us know.</p>
                        <button type="button" onClick={this.resetForm}>Try again</button>
					</div>
				)
			}
			else
			{
				return (
					<div className='api-message'>
						<h1>Success</h1>
						<p>You've successfully uploaded your file to Responsible Lending.</p>
                        <button type="button" onClick={this.resetForm}>Upload Another File</button>
					</div>
				)
			}
		}
	}
}

export default UploadForm;