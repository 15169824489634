import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import './uploader.styles.scss';

import { FaCloudUploadAlt } from 'react-icons/fa';
import { FaFilePdf } from 'react-icons/fa';

function Uploader({handleUpload})
{
    let maxFileSize = 10485760

    const onDrop = useCallback(acceptedFiles => {
        convertToBase64(acceptedFiles[0]);
    }, [])
    
    const {getRootProps, isDragActive, acceptedFiles, fileRejections} = useDropzone({onDrop, accept: '.pdf', maxFiles: 1, maxSize: maxFileSize});
    
    const acceptedFileItems = acceptedFiles.map(file => (
        <span key ={file.path}>
            {file.path}
        </span>
    ));

    const convertToBase64 = (file) => {
        // Call the FileReader Class.
        let reader = new FileReader();

        // Run the readAsDataURL(base64) function passing the file recieved from the uploader.
        reader.readAsDataURL(file);

        // If it works, pass the result to the hidden input.
        reader.onload = function() {
            handleUpload(file.name, reader.result);
        };
        // If it errors, console log the error.
        reader.onerror = function (error) {
            //console.log('Error: ', error);
        };
    };

    if (acceptedFileItems.length == 0)
    {
        return (
            <div>
                <h2 className="mb-3">File Upload:</h2>
                <div className={`uploader ${isDragActive ? 'drag-active' : ''}`} {...getRootProps()}>
                    <FaCloudUploadAlt />
                    <span>
                        { isDragActive ? 'Drop your file to upload' : 'Drag and drop a file to upload, or click here' }
                    </span>
                </div>
            </div>
        )
    }
    else if (acceptedFileItems.length > 0)
    {
        return (
            <div>
                <h2 className="mb-3">File Upload:</h2>
                <div className="uploader file-accepted" {...getRootProps()}>
                    <FaFilePdf />
                    <span>{acceptedFileItems}</span>
                    <span className='file-size'></span>
                </div>
            </div>
        )
    }
};

export default Uploader;