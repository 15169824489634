import React from 'react';

import { ReactComponent as Logo } from '../../assets/images/royal-london-logo.svg';
import { FaLock } from 'react-icons/fa';

import './header.styles.scss';

const Header = ({signOut}) => (
    <header className="header">
        <Logo className="logo" />
        <button id="sign-out-button" onClick={signOut}>
            <span className='pe-2'>Secure Sign Out</span>
            <FaLock />
        </button>
    </header>
)

export default Header;