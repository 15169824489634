import React from "react";

import "./homepage.styles.scss";

import UploadForm from '../../components/upload-form/upload-form.component';

class Homepage extends React.Component {

    render()
    {
        return (
            <div className='Homepage'>
                <section className="upload-section">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h1 className="text-center mb-3">Hi {this.props.user.attributes.email}</h1>
                                <p className="text-center mb-4">
                                    Welcome to the Responsible Lending Suitability File Uploader. You can use this service to easily and securely send the clients suitability file to our team. Use the widget below to upload. Once uploaded, fill in the required form fields and press submit to send the file to our team. Please bear in mind, we can&apos;t complete cases without the suitability letter.
                                </p>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-10">
                                <UploadForm token={this.props.user.signInUserSession.accessToken.jwtToken} />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Homepage;