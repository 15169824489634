import React from 'react';
import { Amplify } from 'aws-amplify';

import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './components/authenticator/authenticator.styles.scss';

import './App.scss';

import Header from './components/header/header.component';
import Homepage from './pages/homepage/homepage.component';

import { ReactComponent as Logo } from './assets/images/royal-london-logo.svg';

//TODO Consider moving Auth information out of app.js into a config file and then importing it in here.
Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: 'eu-west-2',
        userPoolId: 'eu-west-2_7SvAlM5R6',
        userPoolWebClientId: '2dnisi8n3gplpgeluepkr6f0b6'
    }
});

const components = {
    Header() {
        return (
            <div className='logo'>
                <Logo />
            </div>
        );
    },
    Footer() {
        return (
            <span className='copyright-notice'>© {new Date().getFullYear()} Royal London</span>
        )
    },
    SignIn: {
        Footer() {
            return (
                <></>
            );
        },
    },
}

const formFields = {
    setupTOTP: { 
        QR: {
          totpIssuer: 'responsiblelending.co.uk',
        },
    }
}

class App extends React.Component {

    render() {
        return (
            <Authenticator className='authenticator' loginMechanisms={['email']} hideSignUp={true} formFields={formFields} components={components}>
                {({ signOut, user }) => (
                    <div className="App">
                        <Header signOut={signOut}/>
                        <Homepage user={user} />
                    </div>
                )}
            </Authenticator>
        );
    }
}

export default App;
